






























import Vue from 'vue';
import Component from 'vue-class-component';
import Utils from '@/modules/Utils';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, Reports } from '@/store';

@Component({})
export default class GeneralAlerts extends Vue {
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;
    @Global.State('allProjectsTimezone') allProjectsTimezone;
    @Reports.Getter('ruleBoards') ruleBoards;
    
    @Prop() tableData;

    timezone = 'Asia/Tel_Aviv';
    loading = true;

    mounted() {
        this.timezone = this.projectTimezone || this.allProjectsTimezone || 'Asia/Tel_Aviv';
        this.loading = false;
    }

    get headers(){
        const text_align = this.lang === 'en' ? 'text-xs-left' : 'text-xs-right';
        return [
          {
            text: 'Ticket ID',
            value: 'ticket_id',
            class: text_align,
            sortable: false
          },
          {
            text: 'Managed Area',
            value: 'managed_area',
            class: text_align,
            sortable: false
          },
          {
            text: 'Priority',
            value: 'priority_id',
            class: text_align,
            sortable: false
          },
          {
            text: 'Alert Time',
            value: 'updated_at',
            class: text_align,
            sortable: false
          }
        ];
    }
    
    getDateByTimezone(timestamp){
      return Utils.convertTimestamp(timestamp, 'DD/MM/YYYY HH:mm:ss', this.timezone);
    }
  }
