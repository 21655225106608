








































































import Vue from 'vue';
import vuex from '@/store';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Global } from '@/store';
import Utils from '@/modules/Utils';

@Component({})
export default class FullViewReports extends Vue {
  @Global.State('lang') lang;

  @Prop() tableData;

  type = '';
  items = [];
  timezone = 'Asia/Tel_Aviv';
  loading = true;
  sortBy = 'grade';
  descending = false;
  previous_data = null;
  pagination = {
    rowsPerPage: 25,
    totalItems: 0
  };
  
  mounted() {
    this.generateCabinetsTable(this.tableData);
    this.timezone = this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    this.setPageType();

    if (this.type === 'events') {
      this.sortBy = 'cabinetNumber';
    }else {
      this.descending = true;
    }

    const header_data = this.headers.find((header) => header.sort_field === this.sortBy);
    this.changeSort(header_data, false);
    this.pagination.totalItems = this.items.length;
    this.loading = false;
  }

  setPageType(){
    this.type = this.$route.path.includes('events') 
      ? 'events' 
      : this.$route.path.includes('fullViewReport')
        ? 'fullViewReport'
        : this.$route.path.includes('allProjectsAlerts')
          ? 'allProjectsAlerts'
          : 'alerts';
  }

  get headers(){
    const text_align = this.lang === 'en' ? 'text-xs-left' : 'text-xs-right';

    return [
      {
        text: 'Cabinet Number',
        sort_type: 'string',
        sort_field: 'cabinetNumber',
        class: text_align,
        width: '15%'
      },
      {
        text: 'Managed Area',
        sort_type: 'string',
        sort_field: 'roadNumber',
        class: text_align,
        width: '20%'
      },
      {
        text: this.type !== 'events' ? 'Alerts' : 'Events',
        sort_type: 'number',
        sort_field: 'grade',
        class: text_align,
        sortable: this.type !== 'events' ? true : false,
        width: '20%'
      },
      {
        text: 'Cabinet Description',
        sort_type: 'string',
        sort_field: 'description',
        class: text_align,
        width: '20%'
      },
      {
        text: 'Malfunctioning Units',
        sort_type: 'number',
        sort_field: 'faults',
        unit: '%',
        class: text_align,
        width: '15%'
      },
      { 
        text: 'Devices',
        sort_type: 'number',
        sort_field: 'devices_count',
        class: text_align,
        width: '20%'
      }
      // {
      //   text: this.type !== 'events' ? 'Latest Alert Time' : 'Latest Event Time',
      //   sort_type: 'number',
      //   sort_field: this.type !== 'events' ? 'latestAlert' : 'latestEvent',
      //   class: text_align
      // }
    ];
  }

  generateCabinetsTable(cabinets){
    const data = cabinets || this.tableData;
    if (!this.type){
      this.setPageType();
    }

    let data_by_table_format = null;
    if (isEqual(this.previous_data, data)) {
      data_by_table_format = this.generateDataByTableFormat(data);
      if (!isEqual(this.items, data_by_table_format)) {
        this.loading = true;
        this.previous_data = cloneDeep(data);
        this.items = data_by_table_format;
        const sort_field = this.sortBy !== 'cabinetNumber' && this.sortBy !== 'grade' ? this.sortBy : this.type === 'events' ? 'cabinetNumber' : 'grade';
        const header_data = this.headers.find((header) => header.sort_field === sort_field);

        this.changeSort(header_data, false);
        this.loading = false;
      }
    }else {
      this.loading = true;
      this.previous_data = cloneDeep(data);
      this.items = this.generateDataByTableFormat(data);
      const sort_field = this.sortBy !== 'cabinetNumber' && this.sortBy !== 'grade' ? this.sortBy : this.type === 'events' ? 'cabinetNumber' : 'grade';
      const header_data = this.headers.find((header) => header.sort_field === sort_field);

      this.changeSort(header_data, false);
      this.loading = false;
    }
  }

  generateDataByTableFormat(data){
    const items = cloneDeep(data);
    items.forEach((cabinet) => {
      cabinet.devices = this.$store.getters['Reports/cabinets_fixtures'].get(cabinet.id) || [];
      cabinet.devices_count = cabinet.devices.length;
      const cabinet_fault_devices = cabinet.devices
        ? cabinet.devices.filter((related_device) => related_device.sys___active === 0)
        : [];
      const faults = cabinet.devices && cabinet.devices.length ? ((cabinet_fault_devices.length) / cabinet.devices.length) * 100 : 0;
      cabinet.faults = `${+faults.toFixed(0)}%`;
    });
    
    return items;
  }

  getDateByTimezone(timestamp){
    return Utils.convertTimestamp(timestamp, 'DD/MM/YYYY HH:mm:ss', this.timezone);
  }

  openCabinetPage(cabinet) {
    const pid = this.$route.params.pid || this.$store.state.User.project.id;

    if (this.type === 'fullViewReport') {
      this.$router.push(`/fullViewReport/${pid}/cabinet-details/${cabinet.id}`);
    } else if (this.type === 'allProjectsAlerts') {
      this.$router.push(`/allProjectsAlerts/${pid}/cabinet-details/${cabinet.id}`);
    } else if (this.type === 'alerts') {
      this.$router.push(`/alerts/${pid}/cabinet-details/${cabinet.id}`);
    } else {
      this.$router.push(`/events/${pid}/cabinet-details/${cabinet.id}`);
    }
  }

  changeSort(header_data, change_descending) {
    if (header_data.hasOwnProperty('sortable') && !header_data.sortable){
      return;
    }

    if (this.sortBy === header_data.sort_field) {
      if (change_descending) {
        this.descending = !this.descending;
      }
    }else {
      this.sortBy = header_data.sort_field;
      this.descending = false;
    }

    if (this.descending) {
      this.items.sort((cabinet1, cabinet2) => cabinet2[this.sortBy].toString().localeCompare(cabinet1[this.sortBy].toString(), undefined, {numeric: true, sensitivity: 'base'}));
    }else {
      this.items.sort((cabinet1, cabinet2) => cabinet1[this.sortBy].toString().localeCompare(cabinet2[this.sortBy].toString(), undefined, {numeric: true, sensitivity: 'base'}));
    }
  }
}
