<template>
  <v-layout v-if="loading" class="d-flex align-center justify-center" style="padding-top: 15px">
    <v-progress-circular indeterminate="indeterminate" color="primary" size="48"></v-progress-circular>
  </v-layout>
  <v-layout v-else :key="type" :class="$route.path.includes('alerts') || $route.path.includes('events') ? 'px-4 mb-5' : 'mb-5'">
    <v-layout 
      row
      wrap
      v-if="((type === 'alerts' || type === 'fullViewReport') && alertsNum) ||
        (type === 'events' && eventsNum) ||
        (type === 'alerts' && general_alerts.length > 0)"
    >
      <v-flex v-if="$store.state.User.project.id === 'allProjects'" xs12 class="mt-3">
        <v-icon medium @click="goBackAllProjects" color="black">{{ lang === 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right'}}</v-icon>
      </v-flex>
      <v-flex xs12 :style="$route.path.includes('alerts') || $route.path.includes('events') ? 'margin-top: 30px' : 'margin-top: 5px'">
        <div class="tabs-items">
          <div grid-list>
            <v-layout row wrap>
              <v-flex xs12>
                <v-card class="blue-rounded-card pa-3" style="background-color: white !important; overflow: auto">
                  <v-layout>
                    <v-flex xs3>
                      <v-layout>
                        <v-flex xs4 md3>
                          <img v-if="type === 'events'" class="imagePlace" src="@/assets/images/eventImg.png" alt="" />
                          <img v-else class="imagePlace" src="@/assets/images/alertImg.png" alt="" />
                        </v-flex>
                        <v-flex xs8 md9>
                          <v-layout row wrap>
                            <v-flex xs12>
                              <h1 class="font-34 text-bold">{{ type === 'events' ? eventsNum : alertsNum }}</h1>
                            </v-flex>
                            <v-flex xs12>
                              <h4 class="font-16">{{ type === 'events' ? $t('Events') : $t('Cabinets With Alerts') }}</h4>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <template v-if="type === 'events'">
                      <v-flex xl2 lg3 md3 sm3 xs4>
                        <v-layout col>
                          <v-flex xl3 lg3 md3 sm4 xs4>
                            <img class="imagePlace" src="@/assets/images/red.png" alt="" />
                          </v-flex>
                          <v-flex xl9 lg9 md9 sm8 xs8>
                            <v-layout row wrap>
                              <v-flex xs12>
                                <h1 class="font-34 text-bold">{{ highEvent }}</h1>
                              </v-flex>
                              <v-flex xs12>
                                <h4 class="font-16">{{ $t('Consumption anomalies') }}</h4>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xl2 lg3 md3 sm3 xs4>
                        <v-layout col>
                          <v-flex xl3 lg3 md3 sm4 xs4>
                            <img class="imagePlace" src="@/assets/images/yellow.png" alt="" />
                          </v-flex>
                          <v-flex xl9 lg9 md9 sm8 xs8>
                            <v-layout row wrap>
                              <v-flex xs12>
                                <h1 class="font-34 text-bold">{{ midEvent }}</h1>
                              </v-flex>
                              <v-flex xs12>
                                <h4 class="font-16">{{ $t('Abnormal data') }}</h4>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xl2 lg3 md3 sm3 xs4>
                        <v-layout col>
                          <v-flex xl3 lg3 md3 sm4 xs4>
                            <img class="imagePlace" src="@/assets/images/green.png" alt="" />
                          </v-flex>
                          <v-flex xl9 lg9 md9 sm8 xs8>
                            <v-layout row wrap>
                              <v-flex xs12>
                                <h1 class="font-34 text-bold">{{ lowEvent }}</h1>
                              </v-flex>
                              <v-flex xs12>
                                <h4 class="font-16">{{ $t('Consumption during the day') }}</h4>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </template>
                    <template v-else>
                      <v-flex xs4 sm3 xl2>
                        <v-layout>
                          <v-flex xs4 md3>
                            <img class="imagePlace" src="@/assets/images/red.png" alt="" />
                          </v-flex>
                          <v-flex xs8 md9>
                            <v-layout row wrap>
                              <v-flex xs12>
                                <h1 class="font-34 text-bold">{{ high }}</h1>
                              </v-flex>
                              <v-flex xs12>
                                <h4 class="font-16">{{ $t('High') }}</h4>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs4 sm3 xl2>
                        <v-layout>
                          <v-flex xs4 md3>
                            <img class="imagePlace" src="@/assets/images/yellow.png" alt="" />
                          </v-flex>
                          <v-flex xs8 md9>
                            <v-layout row wrap>
                              <v-flex xs12>
                                <h1 class="font-34 text-bold">{{ mid }}</h1>
                              </v-flex>
                              <v-flex xs12>
                                <h4 class="font-16">{{ $t('Medium') }}</h4>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs4 sm3 xl2>
                        <v-layout>
                          <v-flex xs4 md3>
                            <img class="imagePlace" src="@/assets/images/green.png" alt="" />
                          </v-flex>
                          <v-flex xs8 md9>
                            <v-layout row wrap>
                              <v-flex xs12>
                                <h1 class="font-34 text-bold">{{ low }}</h1>
                              </v-flex>
                              <v-flex xs12>
                                <h4 class="font-16">{{ $t('Low') }}</h4>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </template>
                    <v-flex d-flex align-center justify-end>
                      <v-btn slot="activator" @click="cabinet_filter_dialog = true" style="border-radius: 5px" color="#812E81" class="white--text">
                        <v-icon>mdi-filter</v-icon>
                        <span :class="lang === 'en' ? 'ml-2' : 'mr-2'" class="text-transform-none subheading font-weight-medium">{{ $t('Filter By') }}</span>
                      </v-btn>
                      <v-btn v-if="type !== 'events' && filtered_cabinets.length" @click="exportData" style="border-radius: 5px" color="#292F7D" class="white--text">
                        <v-icon>mdi-export</v-icon>
                        <span :class="lang === 'en' ? 'ml-2' : 'mr-2'" class="text-transform-none subheading font-weight-medium">{{ $t('Export Data') }}</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-flex>
      <span v-if="general_alerts.length" class="subheading font-weight-bold mt-4">{{ $t('Flood Alerts') }}</span>
      <v-flex v-if="general_alerts.length" xs12 style="margin-top: 10px" class="blue-rounded-card">
        <GeneralAlerts :tableData="general_alerts" />
      </v-flex>
      <span v-if="cabinets.length > 0 && general_alerts.length > 0" class="subheading font-weight-bold mt-4">{{ $t('Cabinet Alerts') }}</span>
      <v-flex v-if="filtered_cabinets.length > 0" xs12 style="margin-top: 10px" class="blue-rounded-card">
        <ProjectCabinetTable ref="cabinets_table" :tableData="filtered_cabinets" />
      </v-flex>
      <v-flex v-else-if="cabinets.length > 0 && filtered_cabinets.length === 0" xs12>
        <v-layout justify-center class="blue-rounded-card pa-3 mt-3">
          <h2>{{ $t('There are no matching results for your filter') }}</h2>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-else>
      <v-flex v-if="$store.state.User.project.id === 'allProjects'" xs12 class="mt-3">
        <v-icon medium @click="goBackAllProjects" color="black">{{ lang === 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right'}}</v-icon>
      </v-flex>
      <v-layout v-if="type === 'events'" justify-center class="mt-3">
        <h1>{{ $t('There are no events for this project') }}</h1>
      </v-layout>
      <v-layout v-else justify-center class="mt-3">
        <h1>{{ $t('There are no alerts for this project') }}</h1>
      </v-layout>
    </v-layout>
    <FilterDialog 
      v-if="cabinet_filter_dialog"
      :filters="filters"
      :today="today_string"
      :yesterday="yesterday_string"
      :last_week="last_week"
      @updateFilters="updateFilters"
      @close="cabinet_filter_dialog = false"
    />
  </v-layout>
</template>

<script>
import vuex from '@/store';
import ProjectCabinetTable from '@/pages/alertManagement/components/ProjectCabinetTable.vue';
import GeneralAlerts from '@/pages/alertManagement/components/GeneralAlerts.vue';
import Utils from '@/modules/Utils';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import FilterDialog from '@/pages/alertManagement/components/FilterDialog.vue';
import { alerts_with_header_1, alerts_with_header_2, alerts_with_header_3, single_unit_alert_types, single_unit_probability_alert_types } from '@/store/modules/Global';
// import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';

export default {
  name: 'AlertManagment',
  props: ['default_filter'],
  components: { ProjectCabinetTable, GeneralAlerts, FilterDialog },
  data() {
    return {
      cabinets: [],
      alertsNum: 0,
      high: null,
      mid: null,
      low: null,
      type: '',
      eventsNum: null,
      highEvent: null,
      midEvent: null,
      lowEvent: null,
      lang: '',
      loading: true,
      general_alerts: [],
      search: '',
      selectedValue: '',
      items: [],
      search_loading: false,
      filters: null,
      cabinet_filter_dialog: false,
      filtered_cabinets: [],
      project_id: null,
      today_string: '',
      yesterday_string: '',
      last_week: ''
    };
  },
  beforeMount() {
    this.type = this.$route.path.includes('fullViewReport')
      ? 'fullViewReport'
      : this.$route.path.includes('alerts')
        ? 'alerts'
        : 'events';
    this.lang = this.$store.state.Global.lang;
    if (this.type === 'alerts') this.$store.commit('Global/setPageTitle', 'Alert Management');
    else if (this.type === 'events') this.$store.commit('Global/setPageTitle', 'Event Management');
    this.setProjectId();
  },
  mounted() {
    if ((this.type === 'alerts' || this.type === 'fullViewReport') && Object.values(this.alertsList).length === 0) {
      vuex.dispatch('Projects/getAlerts');
    }else if (Object.values(this.eventsList).length === 0){
      vuex.dispatch('Projects/getEvents');
    }

    this.runPage();
  },
  methods: {
    setProjectId(){
      this.project_id = this.$route.params.pid || this.$store.state.User.project.id;
    },
    goBackAllProjects(){
      if (this.$route.params.location === 'fullViewReport') {
        this.$store.commit('Global/setOverviewSelectedTab', 'tab-alerts');
      }
      this.$router.push(`/${this.$route.params.location}`);
    },
    runPage() {
      this.loading = true;
      if ((this.type === 'alerts' || this.type === 'fullViewReport')) {
        if (Object.values(this.alertsList).length !== 0){
          this.setProjectAlerts();
        }
      }else if (Object.values(this.eventsList).length !== 0){
        this.setProjectEvents();
      }
      if (this.cabinetsCount !== -1){
        this.loading = false;
      }
    },
    setProjectAlerts(){
      this.setGeneralAlerts();

      let cabinets = this.getRelevantCabinets();
      const alerts = Utils.getProjectTicketsList(this.$store.state.Projects.alerts[this.project_id]);
      alerts.forEach((alert) => alert.time = moment(alert.created_at).valueOf());
      
      if (!alerts.length) return;

      const project_alerts_by_cabinet = this.$store.state.Projects.alerts[this.project_id].cabinets;
      this.alertsNum = Object.keys(project_alerts_by_cabinet).length;
      this.high = Object.values(project_alerts_by_cabinet).filter((cabinet) => cabinet.top_priority === 3).length;
      this.mid = Object.values(project_alerts_by_cabinet).filter((cabinet) => cabinet.top_priority === 2).length;
      this.low = Object.values(project_alerts_by_cabinet).filter((cabinet) => cabinet.top_priority === 1).length;
      
      cabinets.forEach((cabinet) => {
        const cabinet_id = cabinet['meta.device'].cabinet_id;
        const cabinetAlerts = cabinet_id && project_alerts_by_cabinet[cabinet_id] ? project_alerts_by_cabinet[cabinet_id].list : [];
        const grade = cabinet_id && project_alerts_by_cabinet[cabinet_id] ? project_alerts_by_cabinet[cabinet_id].grade : '';
        
        this.setCabinetAlerts(cabinet, cabinetAlerts, grade);
      });

      cabinets = cabinets.filter((cabinet) => cabinet.alerts.length);

      if (!isEqual(this.cabinets, cabinets)) {
        this.cabinets = cabinets;
        vuex.commit('User/selectCabinets', this.cabinets);
      }

      const final_filters = this.getFilterOptions(this.filters);

      if (this.default_filter || this.filters && this.filters.length) {
        if (!isEqual(this.filters, final_filters)) {
          this.filters = final_filters;
          this.updateCabinetsList();
        }
      }else{
        this.filters = final_filters;
        this.filtered_cabinets = [...this.cabinets];
      }
      if (this.$refs.cabinets_table) this.$refs.cabinets_table.generateCabinetsTable(this.filtered_cabinets);
    },
    setProjectEvents(){
      let cabinets = this.getRelevantCabinets();
      const events = Utils.getProjectTicketsList(this.$store.state.Projects.events[this.project_id]);
      events.forEach((event) => event.time = moment(event.created_at).valueOf());

      if (!events.length) return;

      const project_events = Utils.getProjectTicketsList(this.$store.state.Projects.events[this.project_id]);
      const project_events_by_cabinet = this.$store.state.Projects.events[this.project_id].cabinets;

      this.eventsNum = project_events.length;
      this.highEvent = project_events.filter((event) => event.consumption_category.toLowerCase() === 'consumption anomalies').length;
      this.midEvent = project_events.filter((event) => event.consumption_category.toLowerCase() === 'abnormal data').length;
      this.lowEvent = project_events.filter((event) => event.consumption_category.toLowerCase() === 'consumption during the day').length;
      
      cabinets.forEach((cabinet) => {
        const cabinet_id = cabinet['meta.device'].cabinet_id;
        const cabinetEvents = cabinet_id && project_events_by_cabinet[cabinet_id] ? project_events_by_cabinet[cabinet_id].list : [];
        // const grade = cabinet_id && project_events_by_cabinet[cabinet_id] ? project_events_by_cabinet[cabinet_id].grade : '';
        this.setCabinetEvents(cabinet, cabinetEvents);
      });

      cabinets = cabinets.filter((cabinet) => cabinet.events.length);
      if (!isEqual(this.cabinets, cabinets)) {
        this.cabinets = cabinets;
        vuex.commit('User/selectCabinets', this.cabinets);
      }

      const final_filters = this.getFilterOptions(this.filters);

      if (this.filters && this.filters.length) {
        if (!isEqual(this.filters, final_filters)) {
          this.filters = final_filters;
          this.updateCabinetsList();
        }
      }else{
        this.filters = final_filters;
        this.filtered_cabinets = [...this.cabinets];
      }
      if (this.$refs.cabinets_table) this.$refs.cabinets_table.generateCabinetsTable(this.filtered_cabinets);
    },
    getFilterOptions(prev_filters){
      let filters = prev_filters && prev_filters.length > 1 ? cloneDeep(prev_filters) : [];
      const tickets_list = this.type === 'events' ? 'event' : 'alert';
      
      const options = {};

      if (!filters.length) {
        const today_timestamp = moment().unix() * 1000;
        this.today_string = Utils.convertTimestamp(today_timestamp, 'YYYY-MM-DD', this.projectTimezone);
        this.yesterday_string = Utils.removeFromTimestamp_string(today_timestamp, 'days', 1, 'YYYY-MM-DD', this.projectTimezone);
        this.last_week = Utils.removeFromTimestamp_string(today_timestamp, 'weeks', 1, 'YYYY-MM-DD', this.projectTimezone);

        let range = null;

        if (this.type !== 'events' && this.default_filter) {
          range = this.getFilterRange();
        }

        filters = [
          {
            name: 'Date',
            type: 'date',
            active: range ? true : false,
            from: range && range.from || this.yesterday_string,
            to: range && range.to || this.today_string
          },
          {
            name: 'Managed Area',
            type: 'multiselect',
            field_name: 'managed_area',
            cabinet_field_name: 'roadNumber',
            options: new Map()
          },
          {
            name: 'Cabinet Description',
            type: 'multiselect',
            field_name: 'description',
            cabinet_field_name: 'description',
            options: new Map()
          },
          {
            name: tickets_list === 'event' ? 'Event Type' : 'Alert Type',
            type: 'checkbox',
            field_name: `${tickets_list}_type`,
            options: new Map()
          }
        ];
      }

      filters.forEach((filter) => {
        if (!filter.hasOwnProperty('options')) return;
        
        options[`${filter.field_name}_set`] = new Set();

        this.cabinets.forEach((cabinet) => {
          if (filter.hasOwnProperty('cabinet_field_name') && cabinet[filter.cabinet_field_name]) {
            options[`${filter.field_name}_set`].add(`${cabinet[filter.cabinet_field_name]}`.trim());
          }else if (!filter.hasOwnProperty('cabinet_field_name')){
            cabinet[`${tickets_list}s`].forEach((ticket) => {
              let ticket_field_value = `${ticket[filter.field_name]}`;
              if (!ticket_field_value && filter.field_name === 'event_type' && ticket.consumption_category === 'Abnormal data'){
                ticket_field_value = 'Power supply failure';
              }
              if (ticket_field_value) {
                options[`${filter.field_name}_set`].add(ticket_field_value.trim());
              }
            });
          }
        });
      });

      filters.forEach((filter) => {
        if (filter.hasOwnProperty('options')){
          const options_arr = [...options[`${filter.field_name}_set`]];
          options_arr.sort((a, b) => a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'}));

          if (filter.options.size){
            const filter_options_map = new Map();
            options_arr.forEach((option) => {
                if (filter.options.has(option)) {
                  filter_options_map.set(option, filter.options.get(option));
                } else {
                  filter_options_map.set(option, false);
                }
            });
            filter.options = filter_options_map;
          }else if (options[`${filter.field_name}_set`].size){
            options_arr.forEach((option) => filter.options.set(option, false));
          }else {
            filter.options = new Map();
          }
        }
      });
      
      return filters;
    },
    getFilterRange(){
      const range = { from: null, to: null };

      switch (this.default_filter){
        case 'yesterday':
          range.from = this.yesterday_string;
          range.to = this.yesterday_string;
          break;
        case 'week':
          range.from = this.last_week;
          range.to = this.today_string;
          break;
        default:
          range.from = this.yesterday_string;
          range.to = this.today_string;
          break;
      }

      return range;
    },
    getRelevantCabinets(){
      let cabinets;

      if (this.$route.params.pid){
        cabinets = cloneDeep(this.$store.state.Reports.reportsList.filter(
          (device) => (Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device'])) && device['meta.commission'].commissioned && device.project_id === this.$route.params.pid
        ));
      }else {
        cabinets = cloneDeep(this.$store.getters['Reports/commissionedCabinets']);
      }

      return cabinets;
    },
    setCabinetAlerts(cabinet, alerts, grade){
      if (!alerts.length) {
        cabinet.alerts = [];
        return;
      }

      const meta_device = cabinet['meta.device'];
      const cabinet_id = meta_device.cabinet_id;

      cabinet.cabinetNumber = cabinet_id;
      cabinet.roadNumber = meta_device.managed_area || meta_device.road || '';
      cabinet.grade = grade;
      cabinet.description = meta_device.description || '';

      const cabinet_alerts = this.$store.state.Projects.alerts[this.project_id].cabinets[cabinet_id].list;

      const high_priority = cabinet_alerts.filter((alert) => alert.priority_id === 3).sort((a, b) => a.time - b.time);
      const mid_priority = cabinet_alerts.filter((alert) => alert.priority_id === 2).sort((a, b) => a.time - b.time);
      const low_priority = cabinet_alerts.filter((alert) => alert.priority_id === 1).sort((a, b) => a.time - b.time);
      cabinet.alerts = [...high_priority, ...mid_priority, ...low_priority];

      let latest_date = cabinet.alerts[0].time;
      cabinet.alerts.forEach((alert) => {
        if (alert.time > latest_date) {
          latest_date = alert.time;
        }
      });

      cabinet.latestAlert = latest_date;
      cabinet.high = high_priority.length;
      cabinet.mid = mid_priority.length;
      cabinet.low = low_priority.length;
    },
    setCabinetEvents(cabinet, events){
      if (!events.length) {
        cabinet.events = [];
        return;
      }
      const meta_device = cabinet['meta.device'];
      const cabinet_id = meta_device.cabinet_id;

      cabinet.cabinetNumber = cabinet_id;
      cabinet.roadNumber = meta_device.managed_area || meta_device.road || '';
      cabinet.description = meta_device.description || '';

      const cabinet_events = this.$store.state.Projects.events[this.project_id].cabinets[cabinet_id].list;
      const high_priority = cabinet_events.filter((event) => event.consumption_category.toLowerCase() === 'consumption anomalies').sort((a, b) => a.time - b.time);
      const mid_priority = cabinet_events.filter((event) => event.consumption_category.toLowerCase() === 'abnormal data').sort((a, b) => a.time - b.time);
      const low_priority = cabinet_events.filter((event) => event.consumption_category.toLowerCase() === 'consumption during the day').sort((a, b) => a.time - b.time);
      
      cabinet.events = [...high_priority, ...mid_priority, ...low_priority];
      let latest_date = cabinet.events[0].time;
      cabinet.events.forEach((event) => {
        if (event.time > latest_date) {
          latest_date = event.time;
        }
      });

      cabinet.latestEvent = latest_date;
      cabinet.highEvent = high_priority.length;
      cabinet.midEvent = mid_priority.length;
      cabinet.lowEvent = low_priority.length;
    },
    updateCabinetsList(){
      const date_filter = this.filters.find((filter) => filter.type === 'date' && filter.active);
      let filtered_cabinets = this.cabinets;
      const ticket_type = this.type === 'events' ? 'events' : 'alerts';
      if (date_filter) {
        if (!date_filter.from) date_filter.from = this.today_string;
        if (!date_filter.to) date_filter.to = this.today_string;
        
        filtered_cabinets = filtered_cabinets.filter((cabinet) => {
          return cabinet[ticket_type].some((ticket) => {
            const string_date = moment(ticket.time).tz(this.projectTimezone).format('YYYY-MM-DD');
            return moment(string_date).isBetween(date_filter.from, date_filter.to, undefined, '[]');
          });
        });
      }

      this.filters.filter((filter) => filter.type !== 'date').forEach((filter) => {
        const selected_options = [...filter.options.entries()].filter(([option, value]) => value).map(([option, value]) => option);

        if (selected_options.length) {
          filtered_cabinets = filtered_cabinets.filter((cabinet) => {
            if (filter.hasOwnProperty('cabinet_field_name') && cabinet[filter.cabinet_field_name]) {
              return selected_options.includes(`${cabinet[filter.cabinet_field_name]}`.trim());
            }else {
              return cabinet[ticket_type].some((ticket) => selected_options.includes(`${ticket[filter.field_name]}`.trim()));
            }
          });
        }
      });
      
      this.filtered_cabinets = cloneDeep(filtered_cabinets);
    },
    updateFilters(filters){
      this.filters = filters.map((filter) => {
        if (filter.type === 'multiselect'){
          delete filter.selected_values;
        }
        return filter;
      });
      
      this.updateCabinetsList();
      if (this.$refs.cabinets_table) this.$refs.cabinets_table.generateCabinetsTable(this.filtered_cabinets);
    },
    setGeneralAlerts(){
      this.setFloodAlerts();
    },
    setFloodAlerts(){
      if (!this.alertsList[this.project_id] || !this.alertsList[this.project_id].general_list) return;

      let flood_alerts = this.alertsList[this.project_id].general_list.filter(
        (alert) => alert.alert_type.toLowerCase().includes('flood') && alert.project_id === this.project_id
      );

      flood_alerts = flood_alerts.map((alert) => (
        {
          priority_id: alert.priority_id,
          managed_area: this.getManagedArea(alert.device),
          ticket_id: alert.id,
          updated_at: moment(alert.updated_at).valueOf()
        }
      ));
      this.general_alerts = flood_alerts;
    },
    getManagedArea(device_id){
      const device = this.rule_boards.find((device) => device.id === device_id);
      return device
        ? device['name']
        : '';
    },
    exportData(){
      const cabinet_alert_types = alerts_with_header_1.concat(alerts_with_header_2).concat(alerts_with_header_3);
      const cabinets_alerts = [], single_unit_alerts = [];
      const single_unit_all_alerts = single_unit_alert_types.concat(single_unit_probability_alert_types);

      this.filtered_cabinets.forEach((cabinet) => {
        cabinet.alerts.forEach((alert) => {
          const alert_data_start = {
            ticket_id: `${alert.id}`,
            alert_type: this.$t(alert.alert_type),
            priority: this.$t(Utils.getAlertPriority(alert.priority_id)),
            description: this.$t(Utils.getTicketDescription(alert.title)),
            cabinet_id: cabinet.cabinetNumber || this.$t('no data'),
            managed_area: cabinet['meta.device'].managed_area || cabinet['meta.device'].road || this.$t('no data')
          };

          const device_id = alert.device;

          const alert_data_end = {
            creation_date: Utils.convertTimestamp(alert.time, 'DD/MM/YYYY HH:mm:ss', this.projectTimezone),
            device_id
          };

          if (cabinet_alert_types.includes(alert.alert_type)) {
            alert_data_start.details = alerts_with_header_2.includes(alert.alert_type) 
              ? alert.phase && alert.phase !== 'no data' ? `${this.$t('Phase')} ${Utils.convertNumberToRealPhase(alert.phase)}` : this.$t('no data')
              : alerts_with_header_3.includes(alert.alert_type)
                ? alert.circuit_number && alert.circuit_number !== 'no data' ? `${this.$t('Circuit')} ${alert.circuit_number}` : this.$t('no data')
                : '';
            alert_data_start.circuit_number = alerts_with_header_3.includes(alert.alert_type) ? this.$t(alert.circuit_number) || this.$t('no data') : '';
            alert_data_start.pole_number = '';
            alert_data_start.phase = alerts_with_header_2.includes(alert.alert_type) ? this.$t(Utils.convertNumberToRealPhase(alert.phase)) || this.$t('no data') : '';
            cabinets_alerts.push({...alert_data_start, ...alert_data_end});
          }else if (single_unit_all_alerts.includes(alert.alert_type)){
            const device = this.$store.getters['Reports/commissionedFixtures_map'].get(alert.device);
            alert_data_start.circuit_number = this.$t(alert.circuit_number) || this.$t('no data');
            alert_data_start.pole_number = this.$t(alert.pole_number) || this.$t('no data');
            alert_data_start.phase = this.$t(alert.phase) || this.$t('no data');
            alert_data_start.road_curbs = device && this.$t(device['meta.device'].road_curbs) || this.$t('no data');

            single_unit_alerts.push({...alert_data_start, ...alert_data_end});
          }
        });
      });
      this.generateExcel(cabinets_alerts, single_unit_alerts);
    },
    generateExcel(cabinets_alerts, single_unit_alerts){
      const workbook = new Excel.Workbook();
      const columns_start = [
        { header: this.$t('Ticket Id'), key: 'ticket_id', width: 15 },
        { header: this.$t('Alert Type'), key: 'alert_type', width: 20 },
        { header: this.$t('Priority'), key: 'priority', width: 15 },
        { header: this.$t('Alert Description'), key: 'description', width: 35 },
        { header: this.$t('Cabinet Id'), key: 'cabinet_id', width: 15 },
        { header: this.$t('Managed Area'), key: 'managed_area', width: 15 }
      ];
      const single_unit_columns = [
        { header: this.$t('Circuit Number'), key: 'circuit_number', width: 20 },
        { header: this.$t('Pole Number'), key: 'pole_number', width: 20 },
        { header: this.$t('Phase'), key: 'phase', width: 20 },
        { header: this.$t('Road Curbs'), key: 'road_curbs', width: 15 }
      ];
      const cabinets_columns = [
        { header: this.$t('Details'), key: 'details', width: 20 }
      ];
      const columns_end = [
        { header: this.$t('Date Time'), key: 'creation_date', width: 20 },
        { header: this.$t('Device Id'), key: 'device_id', width: 35 }
      ];

      let cabinets_sheet = null, single_unit_sheet = null, all_sheet = null;

      if (cabinets_alerts.length && single_unit_alerts.length){
        all_sheet = workbook.addWorksheet(this.$t('All'));
        all_sheet.columns = columns_start.concat(single_unit_columns).concat(columns_end);
        all_sheet.addRows(cabinets_alerts.concat(single_unit_alerts));
      }
      if (cabinets_alerts.length) {
        cabinets_sheet = workbook.addWorksheet(this.$t('Cabinet Alerts'));
        cabinets_sheet.columns = columns_start.concat(cabinets_columns).concat(columns_end);
        cabinets_sheet.addRows(cabinets_alerts);
      }
      if (single_unit_alerts.length) {
        single_unit_sheet = workbook.addWorksheet(this.$t('Single Unit Alerts'));
        single_unit_sheet.columns = columns_start.concat(single_unit_columns).concat(columns_end);
        single_unit_sheet.addRows(single_unit_alerts);
      }

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;'});
        saveAs(blob, `${this.project.name}_alerts_${Utils.getNow(this.projectTimezone)}.xlsx`);
      });
    }
  },
  computed: {
    projectTimezone() {
      return this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    },
    project() {
      return this.$store.state.User.project;
    },
    rule_boards(){
      return this.$store.getters['Reports/ruleBoards'];
    },
    alertsList(){
      return this.$store.state.Projects.alerts;
    },
    eventsList(){
      return this.$store.state.Projects.events;
    },
    language() {
      return this.$store.state.Global.lang;
    },
    devicesCount() {
      return this.$store.state.Reports.devicesCount;
    },
    devices() {
      return this.$store.state.Reports.reportsList;
    },
    projectsList(){
      return this.$store.state.Projects.list;
    },
    cabinetsCount(){
      return this.$store.state.Reports.cabinetsCount;
    }
  },
  watch: {
    alertsList(prev, next) {
      if (this.type !== 'events' && !isEqual(prev, next)) {
        this.runPage();
      }
    },
    eventsList(prev, next) {
      if (this.type === 'events' && !isEqual(prev, next)) {
        this.runPage();
      }
    },
    devices() {
      this.runPage();
    },
    projectsList(){
      const project_id = this.project_id;
      this.setProjectId();
      if (project_id !== this.project_id){
        this.runPage();
      }
    }
  }
};
</script>

<style scoped>
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
    margin: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #292f7d;
    border-radius: 5px;
  }

.cabinetSelector{
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  color:blue;
}
.tabs-items {
  background-color: #f2f4f8;
  
}

.imagePlace {
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 480px) {
  .font-34 {
    margin: 0px 5px;
  }
  .font-16 {
    margin: 0px 5px;
  }
}
@media (max-width: 479px) {
  .font-34 {
    margin: 0px 10px;
  }
  .font-16 {
    margin: 0px 10px;
  }
}
.iconSize {
  height: 20px;
  width: 20px;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-container-small {
  width: 500px;
  margin: 0px auto;
  height: fit-content;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.closeIcon {
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
}
.selectHeader{
  font-weight: bold;
  font-size: 18px;
}
</style>
