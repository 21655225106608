






















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import { Global } from '@/store';
import DatePicker from '@/pages/reports/components/DatePicker.vue';
import PropUtils from '@/modules/PropUtils';

@Component({
    components: {
        DatePicker
    }
})
export default class FilterDialog extends Vue {
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;

    @Prop() filters;
    @Prop() today;
    @Prop() yesterday;
    @Prop() last_week;

    dialog = true;
    filters_list = [];
    date_active = '';
    prop_utils = PropUtils;
    date_filter = {
        yesterday: false,
        last_week: false
    };

    mounted(){
        if (this.filters.filter((filter) => filter.options && filter.options.size || filter.type === 'date').length) {
            this.filters_list =  cloneDeep(this.filters);
            this.filters_list
                .filter((filter) => filter.type === 'multiselect')
                .forEach((filter) => {
                    filter.selected_options = [...filter.options.entries()]
                        .filter(([option, value]) => value)
                        .map(([option, value]) => option);
                });
            
            let filter_by_date = this.filters_list.filter((filter) => filter.type === 'date');
            filter_by_date = filter_by_date.length ? filter_by_date[0] : null;

            if (filter_by_date && filter_by_date['active']){
                this.updateDateFilter(filter_by_date);
            }
        }
    }

    handleDateChanged(filter, field, value){
        filter[field] = value;
        this.updateDateFilter(filter);
    }

    updateDateFilter(filter){
        this.date_filter.yesterday = filter.from === this.yesterday && filter.to === this.yesterday || false;
        this.date_filter.last_week = filter.from === this.last_week && filter.to === this.today || false;
    }

    getMultiselectValues(options){
        return [...options.entries()].filter(([option, value]) => value).map(([option, value]) => option);
    }

    updateDates(filter, period){
        if (period === 'yesterday') {
            filter.from = this.yesterday;
            filter.to = this.yesterday;
            this.date_filter.yesterday = true;
            this.date_filter.last_week = false;
        }else if (period === 'week') {
            filter.from = this.last_week;
            filter.to = this.today;
            this.date_filter.last_week = true;
            this.date_filter.yesterday = false;
        }
    }

    getMultiselectItems(options){
        return [...options.keys()];
    }

    get filter_width(){
        return this.$vuetify.breakpoint.xlOnly 
            ? '30vw' 
            : this.$vuetify.breakpoint.mdAndUp
                ? '40vw'
                : '70vw';
    }

    applyFilter() {
        this.filters_list
            .filter((filter) => filter.type === 'multiselect')
            .forEach((filter) => [...filter.options.keys()].forEach((option) => filter.options.set(option, filter.selected_options.includes(option))));
        
        this.$emit('updateFilters', this.filters_list);
        this.$emit('close');
    }

    clearSelection(){
        const filters = cloneDeep(this.filters_list);
        filters.forEach((filter) => {
            if (filter.type === 'date') {
                filter.active = false;
                filter.from = this.yesterday;
                filter.to = this.today;
                return;
            }
            
            [...filter.options.keys()].forEach((option) => filter.options.set(option, false));
            if (filter.type === 'multiselect') {
                filter.selected_options = [];
            }
        });

        this.filters_list = filters;
    }
}
